body {
  font-family: "Times New Roman", Times, serif !important;
  color: #232323 !important;
}

a {
  color: white;
}

a:hover {
  color: white;
}

.justify {
  text-align: justify;
}

h1 {
  font-weight: 800;
  font-size: 3rem;
}

.font-dark {
  color: #232323 !important;
}

.font-light {
  color: white;
}

.bg-dark {
  background-color: #232323 !important;
  color: white;
}
